import {useController} from "~/store/controllerStore";
import {ButtonController} from "~/types/ButtonController";
import {useGame} from "~/store/gameStore";

export default defineNuxtRouteMiddleware(async (to) => {
    const controller = useController();

    // if (to.name === 'slot-name-id') {
    //     console.log('xxx')
    // }

    if (to.path === "/login") {
        return;
    }
    
    // if (to.path === '/tournament') {
    //     return
    // }

    if (
        !localStorage.getItem("isLogin") &&
        localStorage.getItem("isLogin") !== "y"
    ) {
        return "/login";
    }

    // @ts-ignore
    await controller.getSetting();
    const menu: ButtonController = controller.buttonControllers.find(
        (item: ButtonController): boolean => {
            return item.path === to.path || item.path + "/" === to.path;
        }
    );

    if (menu && menu?.status === false) {
        return "/";
    }

    return;
});
